.container {
  z-index: 1;
  position: fixed;
  right: 40px;
  padding: 5px;
  display: flex;
  gap: 14px;
  align-items: center;
  justify-content: center;
  transition: all 0.4s ease;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 8px 32px 0 rgba(26, 27, 35, 0.37);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);

  .text {
    margin: 8px;
    color: white;
    font-size: 0.8rem;
    text-align: center;
  }

  .qr_code {
    width: 90px;
    height: 90px;
  }
}

@media (max-width: 700px) {
  .container {
    display: none;
  }
}
