@import "../../styles/partials/colors";

.famcoins_container {
  position: relative;

  .features_container {
    margin-left: -1rem;
    max-width: 45rem;

    .phone_image {
      height: 34.25rem;
      width: auto;
    }
  }
}

.famcoin_card {
  margin: 15px 40px 15px -5px;
  max-width: 23rem;
  padding: 2.5rem 1.5rem;
  position: relative;

  .heading {
    color: $white;
    font-weight: 600;
    margin-bottom: 0;

    img {
      height: 1.5rem;
      margin-left: 0.6rem;
      width: auto;
    }
  }

  .divider {
    margin: 2rem 0;
    background-color: $scroll-bar-grey;
    border: 0;
    height: 2px;
    opacity: 1;
    width: 30%;
  }

  .famcard_description {
    overflow: hidden;
    position: relative;

    .fam_perks {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $background-light-grey;
      border-radius: 0.6rem;
      margin: 2rem 0;
      padding: 0.6rem;
      vertical-align: middle;
    }
    .fam_perks:first-of-type {
      margin: 0;
    }
    .fam_perks_text {
      margin-left: 10px;
      color: #e2e2e2;
      font-weight: 400;
    }
    img {
      max-width: 40px;
      max-height: 40px;
    }
  }

  .mob_pointer {
    position: absolute;
    top: -0.75rem;
  }

  .pointer {
    left: -1.5rem;
    position: absolute;
    top: 3rem;
    transform: rotateZ(-90deg);
  }
}

.animated {
  animation: maxHeight 0.5s ease-out;
}

.mob_famcoin_card_container {
  display: flex;
  justify-content: center;

  .mob_card_selector {
    color: $background-header-text-grey;
    padding: 2rem 2rem 0 0;

    svg {
      margin-left: 0.5rem;
      vertical-align: text-top;
    }
  }
}

@keyframes maxHeight {
  from {
    max-height: 0;
    opacity: 0.3;
  }

  to {
    max-height: 500px;
    opacity: 1;
  }
}

@media (max-width: 991px) {
  .famcoins_container {
    display: flex;
    justify-content: center;
    align-items: center;
    .features_container {
      width: max-content;
      padding: 0;
    }
  }
  .mob_phone_image_container {
    display: none;
  }
}

@media (max-width: 575px) {
  .mob_famcoin_card_container {
    .mob_card_selector {
      padding: 1rem 0 0;

      p {
        font-size: 1.1rem;

        svg {
          margin-left: 0.6rem;
          margin-top: -0.3rem;
        }
      }
    }
  }

  .famcoin_card {
    width: 100%;
    text-align: center;
    transform: translateX(5%);
    margin-left: 10px;

    .divider {
      transform: translateX(100%);
    }
    .famcard_description {
      li {
        padding: 0.5rem 0 0.4rem 2.1rem;

        &:before {
          top: 0.05rem;
        }
      }
    }
  }
}
