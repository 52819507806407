@import "./partials/colors";

.header_content_container {
  bottom: 30vh;
  left: 7%;
  position: absolute;
  width: 90%;

  h1 {
    max-width: 650px;
    font-size: 3.5rem;
  }

  .btns {
    margin: 2rem 0px 4rem;
  }
  .lead_container {
    margin-left: -0.5rem;
  }
}

.heading {
  font-size: 3.3rem;
  line-height: 4.2rem;

  &_container {
    font-weight: 700;
    overflow: hidden;
    height: 4rem;

    &_text {
      margin-bottom: 4.1rem;
      display: inline;
      margin: 0;
    }

    &_list {
      margin-top: 0;
      padding-left: 5.5rem;
      list-style: none;
      animation: change 10s infinite;

      &_item {
        width: max-content;
        line-height: 4rem;
        margin: 0;
      }
    }
  }
}

.bottom_links_container {
  left: 0;
  bottom: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 10rem;
  padding: 0 7%;
  width: 100vw;
  background-color: $background-dark-grey;

  img {
    height: 3rem;
  }
  .poweredBy {
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    color: #ffffff;
  }

  hr {
    background-color: $footer-grey;
    height: 40%;
    margin: 0;
    transform: rotate(90);
    width: 1px;
  }
}

.bottom_links_container_mobile {
  .wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 3rem;
  }
  .poweredBy {
    text-align: center;
    font-weight: 400;
    font-size: 1rem;
    line-height: 25px;
    margin: 2rem 0px;
    color: #ffffff;
  }
  img {
    width: 9rem;
  }

  hr {
    background-color: $text-light-grey;
    height: 5rem;
    margin: 0;
    margin-top: 3rem;
    transform: rotate(90);
    width: 1px;
  }
}
.phone_image {
  transform: scale(1.3);
  max-height: 40rem !important;
}

.mob_header_content_container {
  top: 50%;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  margin-top: 5rem;

  h1 {
    max-width: 600px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
  }

  img {
    height: auto;
    max-height: 12rem;
    padding-right: 2rem;
    width: 10rem;
  }

  .ratings_container {
    align-items: center;
    display: flex;

    img {
      transform: scale(1.2);
    }
  }

  .links_container {
    align-items: center;
    display: flex;
    left: 0;
    width: 100vw;

    .download_links {
      p {
        margin-bottom: 0.2rem;
      }

      img {
        height: 3rem;
        max-width: 12rem;
        padding-right: 0.5rem;
        width: auto;
      }
    }
  }
}

.mob_upi {
  padding: 50px 10px;
  margin-bottom: -50px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  .upi_img_mob {
    width: 10rem;
    height: 10rem;
  }

  p {
    text-align: center;
    max-width: 70%;
    font-size: 1.2rem;
  }
}
.mob_lead_container {
  background-color: $background-dark-grey;
  padding: 20px 12.75px;
  text-align: center;
  width: 100%;
}
.white_bg {
  background-color: #fff;
  width: 100%;
}
.famcoins_perks_section,
.mob_famcoins_perks_section {
  background-color: $black;
  background: linear-gradient(
    262.76deg,
    #000000 53.8%,
    rgba(0, 0, 0, 0) 112.63%
  );
  padding-bottom: 4rem;
  padding-top: 4rem;
  position: relative;

  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-end;

  .rewards_content {
    width: max-content;
    h1 {
      img {
        height: 3rem;
        margin-right: 1rem;
        vertical-align: text-top;
      }
    }
  }
}

.mob_famcoins_perks_section {
  background-color: $background-dark-grey;
  align-items: center;
  .description_text {
    color: $background-header-text-grey;
    max-width: 90%;
    padding-bottom: 2rem;
  }
}

@media (max-width: 520px) {
  .all_transaction_image {
    object-fit: contain;
  }
}

.famcard_section {
  display: flex;
  align-items: center;
  padding-bottom: 12rem;
  padding-top: 12rem;
  background: #242424;

  .famcard_image {
    max-height: 40rem;
  }
}

.help_section {
  padding-bottom: 8rem;
  padding-top: 8rem;

  .image_container {
    justify-content: flex-end;

    img {
      max-height: 30rem;
    }
  }

  .help_text_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
  }
}

.Testimonial {
  background-color: #262626;
  padding: 10rem 0;
  // margin-bottom: -8rem;
}

.trust_section {
  display: flex;
  justify-content: center;
  .sponsor_container {
    &:nth-child(1) {
      img {
        width: 200px;
      }
    }
    &:nth-child(7) {
      img {
        width: 135px;
      }
    }
  }

  a {
    align-items: center;
    display: flex;
    height: 5rem;
    justify-content: center;
    margin: 0 0.5rem 1.5rem 0.5rem;
    width: 90%;

    img {
      height: auto;
      max-height: 2.5rem;
      max-width: 100%;
      width: auto;
      transform: scale(1.3);
    }
  }

  a:last-child {
    img {
      max-height: 3.2rem;
    }
  }
}

.hiring_section {
  padding-bottom: 12rem;
  padding-top: 8rem;
  background: #242424;

  .hiring_text_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .image_content {
    max-height: 35rem;
  }

  .landing_visa_image {
    max-height: 450px;
    max-width: 450px;
    opacity: 0.6;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    z-index: -1;
  }
}

@media (max-width: 1599px) {
  .upi_img {
    transform: scale(1.5);
    margin-right: 5rem;
  }
  .phone_image {
    max-height: 25rem !important;
  }
}

@media (max-width: 1399px) {
  .heading {
    font-size: 3rem;
    line-height: 3rem;
  }
  .lead_container {
    max-width: 100%;
  }
  .header_content_container {
    left: 4%;
  }
  .bottom_links_container {
    padding: 0 4%;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .heading {
    .heading_container_list {
      padding-left: 5rem;
    }
  }

  .phone_image {
    margin-left: 2rem;
    max-height: 25rem !important;
  }
}

@media (max-width: 991px) {
  .header_content_container {
    left: 5%;
  }
  .heading {
    .heading_container_list {
      margin-top: 2px;
      padding-left: 5rem;
    }
  }
  .help_section {
    .image_container {
      justify-content: center;
    }
  }

  .bottom_links_container {
    padding: 0 5%;
  }
  .phone_image {
    margin-left: 2rem;
    margin-bottom: 2rem;
    // max-height: 40rem !important;
  }
}
@media (max-width: 767px) {
  .heading {
    font-size: 2.6rem;
    line-height: 3.2rem;
    .heading_container_list {
      margin-top: 5px;
      padding-left: 5rem;
    }
  }

  .trust_section {
    a {
      img {
        transform: scale(1);
      }
    }
  }
}

@media (max-height: 767px) {
  .header_content_container {
    bottom: 25vh;
  }
  .bottom_links_container {
    height: 8rem;
  }
}

@media (max-height: 640px) {
  .mob_header_content_container {
    bottom: 8rem;
  }
}

@media (max-width: 575px) {
  .heading {
    font-size: 2.2rem;
    line-height: 3rem;
    .heading_container_list {
      margin-top: 6px;
      padding-left: 4rem;
    }
  }

  .mob_header_content_container {
    width: calc(100% - 40px);

    h1 {
      max-width: 100%;
      // padding-top: 1rem;
    }

    .links_container {
      width: 100%;

      .download_links {
        img {
          height: 3.5rem;
          margin-top: 1rem;
          max-width: 12rem;
          padding-right: 0.8rem;
        }
      }
    }
  }

  .famcoins_perks_section,
  .mob_famcoins_perks_section {
    h1 {
      img {
        height: 2.5rem;
      }
    }
  }
}

@media (max-width: 340px) {
  .heading {
    font-size: 1.8rem;
    line-height: 2.4rem;
    .heading_container_list {
      margin-top: 10px;
      padding-left: 3rem;
    }
  }
}

@keyframes change {
  0% {
    transform: translateY(-33%);
  }
  25% {
    transform: translateY(-66%);
  }
  50% {
    transform: translateY(-100%);
  }
  75% {
    transform: translateY(-66%);
  }
  100% {
    transform: translateY(-33%);
  }
}
