@import "../../styles/partials/colors";

.mob_scrollable_container {
  margin-left: -5%;
  overflow-x: scroll;
  padding-left: 5%;
  width: 100vw;

  &::-webkit-scrollbar {
    width: 0;
  }

  .testimonial_card_container {
    display: flex;

    .testimonial_card {
      background-color: #1e1e1e;
      border-radius: 0.6rem;
      height: 40rem;
      margin: 0 0.8rem;
      max-height: 40rem;
      min-width: 22rem;
      padding: 0 0 1rem;
      position: relative;
      text-align: left;

      .profile_image_container {
        border-radius: 10px;
        background-size: cover;
        background-repeat: no-repeat;
        height: 18rem;
        margin-bottom: 1rem;
        object-fit: contain;
        overflow: hidden;
        width: 100%;
      }

      .text_content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 0 1.2rem;

        .ratings_container {
          padding-bottom: 1rem;

          img {
            display: inline-block;
          }
        }

        .testimonial_headline {
          font-size: 1.3rem;
          font-weight: 600;
        }

        .testimonial_description {
          margin-top: 1.5rem;
          font-size: 1.1rem;
          line-height: 1.4rem;
        }

        .source_info {
          bottom: 0;
          font-size: 0.9rem;
          line-height: 1.2rem;
          position: absolute;

          .author_info {
            margin-bottom: 2.5rem;
            font-size: 1.05rem;
            font-weight: 700;
          }

          img {
            margin-right: 0.4rem;
          }
        }
      }
    }
  }
}

.testimonial_container {
  height: auto;
  justify-content: center;
  min-height: 25rem;
  position: relative;
  width: 100%;

  .testimonial_cards_container {
    max-width: 700px;

    .testimonial_card {
      background-repeat: no-repeat;
      background-size: cover;
      border-radius: 0.6rem;
      height: 24rem;
      width: 21rem;
    }

    .arrow_left {
      cursor: pointer;
      left: -1rem;
      position: relative;
      top: 1rem;
      transform: rotateZ(90deg);
      z-index: 1;
    }

    .arrow_right {
      cursor: pointer;
      position: relative;
      right: -1rem;
      top: 1rem;
      transform: rotateZ(-90deg);
      z-index: 1;
    }
  }

  .testimonial_text_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    width: 80%;

    .ratings_container {
      padding-bottom: 1rem;

      img {
        display: inline-block;
      }
    }

    .testimonial_headline {
      font-weight: 600;
      padding-bottom: 0.1rem;
    }

    .testimonial_description {
      line-height: 1.8rem;
    }

    .author_info {
      margin-bottom: 2.5rem;
      font-weight: 600;
    }

    .source_info {
      display: inline-block;

      img {
        margin-right: 0.5rem;
        vertical-align: text-top;
      }
    }
  }
}

@media (max-width: 1399px) {
  .testimonial_container {
    .testimonial_cards_container {
      .testimonial_card {
        height: 22rem;
        width: 20rem;
      }
    }
  }
}

@media (max-width: 1199px) {
  .testimonial_container {
    .testimonial_cards_container {
      .testimonial_card {
        height: 20rem;
        width: 17rem;
      }
    }
  }
}

@media (max-width: 991px) {
  .testimonial_container {
    .testimonial_cards_container {
      max-width: none;
      min-height: 20rem;
    }

    .testimonial_text_container {
      margin-top: 4rem;
      max-width: none;
    }
  }
}

@media (max-width: 575px) {
  .mob_scrollable_container {
    margin-left: -8%;
    overflow-x: scroll;
    padding-left: 8%;
    width: 105vw;
  }

  .testimonial_container {
    width: auto;

    .testimonial_cards_container {
      .testimonial_card {
        height: 17rem;
        width: 15rem;
      }
    }

    .testimonial_text_container {
      padding-left: 0;
    }
  }
}
